import React, { useEffect, useState } from 'react'
import SearchInputHeader from "../SearchInputHeader";
import { AddButton, TableStyleLanguage, PaginationStyle } from "../../styles/MainLayout";
import ReactPaginate from 'react-paginate';
import { CardStyle, AddButtonMobile } from "../../styles/MainLayoutResponsive";
import { setSideBar } from "../../../redux/Functions/actions";
import { connect } from "react-redux";
import axios from '../../../axios';
import "./Gender.scss"
import DeleteGender from '../../Modal/Gender/DeleteGender';
import AddGender from '../../Modal/Gender/AddGender';

const Gender = (props) => {
  const [viewportSize, setViewportSize] = useState(window.innerWidth); //DESKTOP VIEW & MOBILE VIEW
  window.addEventListener("resize", () => {
    setViewportSize(window.innerWidth);
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [dataToModal, setDataToModal] = useState(null);

  //AXIOS (Get all genders)
  const [allGenders, setAllGenders] = useState([]);
  const getAllGenders = () => {
    axios
      .get("/api/admin/genders/all")
      .then((response) => {
        console.log("gender", response.data);
        setAllGenders(response.data)
      setFilteredData(response.data)

      }).catch(err => {
        console.log(err)
      })
  }
  useEffect(() => {
    getAllGenders();
  }, [])
  //AXIOS



  // FILTER FUNCTION
  const [filteredData, setFilteredData] = useState();
  const filterByContent = async (filterValue) => {
    if (filterValue) {
      const filteredArray = allGenders.filter((el) =>
        el?.name_al?.toLowerCase().includes(filterValue?.toLowerCase())
      );
      setFilteredData(filteredArray);
    } else if (filterValue == "") {
      setFilteredData(allGenders);
    }
  };

  

  //PAGINATION
  const [currentPage, setCurrentPage] = useState(false);
  const startIndex = currentPage * 8;
  const endIndex = startIndex + 8;
  const numPages = Math.ceil(filteredData?.length / 8)
  const data = filteredData?.slice(startIndex, endIndex);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  //PAGINATION

  const handleModalAdd = () => {
    setIsOpen(true);
    setModalType("add");
  };
  const handleModalEdit = (data) => {
    setIsOpen(true);
    setModalType("edit");
    setDataToModal(data)
  };
  const handleModalClose = () => {
    setIsOpen(false);
    setIsOpenDelete(false)
  }
  const handleModalDelete = (data) => {
    setIsOpenDelete(true);
    setModalType("delete");
    setDataToModal(data)
  }


  if (viewportSize > 800) {
    return (
      <>
        <SearchInputHeader filterByContent={filterByContent} />
        {isOpen === true ? (
          <AddGender
            getNewGender={() => getAllGenders()}
            isOpen={isOpen}
            type={modalType}
            dataToModal = {dataToModal}
            handleModalClose={handleModalClose}
          />
        ) : (
          ""
        )}
{isOpenDelete === true ? (
          <DeleteGender
            getNewGender={() => getAllGenders()}
            isOpen={isOpenDelete}
            type={modalType}
            dataToModal = {dataToModal}
            handleModalClose={handleModalClose}
          />
        ) : (

          ""
        )}

        <TableStyleLanguage collapse={props?.sidebar}>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col-3">Gjinia Shqip</th>
                <th scope="col-6">Gender English</th>
                <th scope="col-3">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((el) => {
                console.log('olaSingleLabel', el)
                return (
                  <tr>
                    <td className="col-4">{el?.name_al}</td>
                    <td className="col-6">{el?.name_en}</td>
                    <td className="col-3">
                      <div className="d-flex gap-3">
                        <i class="fa-regular fa-pen-to-square iconsForCursor" onClick={() => handleModalEdit(el)} />
                        <i class="fa-regular fa-trash-can iconsForCursor" onClick={() => handleModalDelete(el)} />
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </TableStyleLanguage>
        <PaginationStyle collapse={props?.sidebar}>
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={numPages}
            onPageChange={handlePageChange}
            containerClassName={"pagination justify-content-center"}
            activeClassName={"active"}
          />
        </PaginationStyle>
        <AddButton >
          <i
            type={"button"}
            className="fa-solid fa-circle-plus fa-xl addButtonModalLanguage"
            onClick={() => handleModalAdd()}>
          </i>
        </AddButton>
      </>
    );
  } else
    return (
      <>
        <SearchInputHeader filterByContent={filterByContent} />
        {isOpen === true ? (
          <AddGender
            getNewGender={() => getAllGenders()}
            isOpen={isOpen}
            type={modalType}
            dataToModal = {dataToModal}
            handleModalClose={handleModalClose}
          />
        ) : (
          ""
        )}
{isOpenDelete === true ? (
          <DeleteGender
            getNewGender={() => getAllGenders()}
            isOpen={isOpenDelete}
            type={modalType}
            dataToModal = {dataToModal}
            handleModalClose={handleModalClose}
          />
        ) : (

          ""
        )}

        <div className="container">
             {data?.map((el) => {
              return(
                <>
          <CardStyle>
            <div className="mb-3">
              <div className="row">
                <div className="col-8">
                  <ul class="list-group gap-3 pt-2 pb-2 ps-3">
                    <li class="list-group"><strong>Gjinia Shqip:</strong>  {el?.name_al}</li>
                    <li class="list-group"><strong>Gender English:</strong>  {el?.name_en}</li>
                  </ul>
                </div>
                <div className="col-3 pt-2">
                <div className="d-flex gap-3">
                        <i class="fa-regular fa-pen-to-square iconsForCursor" onClick={() => handleModalEdit(el)} />
                        <i class="fa-regular fa-trash-can iconsForCursor" onClick={() => handleModalDelete(el)} />
                      </div>
                </div>
              </div>
            </div>
          </CardStyle>
          </>
              )
             })}
          <AddButtonMobile>
            <i
              class="fa-solid fa-circle-plus d-flex flex-row-reverse fa-2xl addButtonLanguageMobile"
              onClick={() => handleModalAdd()}
            />
          </AddButtonMobile>
        </div>
      </>
    );


}
const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    sidebar: state?.data?.sidebar
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSideBar: (data) => dispatch(setSideBar(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Gender);

