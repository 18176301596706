import React, { } from 'react'
import { useState } from 'react';
import { ModalStyleMobile, ModalCloseButtonMobile, CloseButtonMobile } from "../../styles/MainLayoutResponsive"
import ModalPortal from "../../../portal/ModalPortal";
import {
    FormTitle, LanguagesForm, FormControl, ModalEditButton, ModalStyle, ModalCloseButton,
    ModalDarkBackGround, DivForLabelAndInputUser, LabelProfile, InputProfileForm, ModalEditButtonAdd, CloseButton, DivForLabelAndInputName, NameFirst
} from "../../styles/MainLayout";
import axios from '../../../axios';
import { toast } from 'react-toastify';
import "../../../components/form/Genders/Gender.js"
const AddGender = (props) => {
    console.log("a po vini", props)
    const [key, setKey] = useState("");
    const [name_al, setName_al] = useState("");
    const [name_en, setName_en] = useState("");

    const [oneTime, setOneTime] = useState(false);


    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });
    console.log('shabangashiprops', props)
    const body = {
        key: key,
        name_al: name_al,
        name_en: name_en
    };
    //AXIOS-- Add new Label
    const addPostGender = (e) => {
        e.preventDefault();
        setOneTime(true);

        axios
            .post("/api/admin/genders", body)
            .then((response) => {
                props?.handleModalClose();
                props?.getNewGender()
                toast.success("New label created!")
                console.log(response)
                setOneTime(false);
            }).catch(err => {
                toast.error("Error creating new label!")

                console.log(err)
            })
    }
    //AXIOS-- Edit new Label
    const editBody = {
        id: props?.dataToModal?.id,
        name_al: name_al,
        name_en: name_en
    }
    const handleEdit = (e) => {
        e.preventDefault();
        axios
            .put("/api/admin/genders/update", editBody)
            .then((response) => {
                console.log("olaLabels", response);
                props?.handleModalClose();
                props?.getNewGender();
                toast.success("Gender edited sucessfully.")
            }).catch(err => {
                toast.error(err)
                console.log(err)
            })
    };


    console.log("PROPSAT", props)
    if (props?.isOpen) {
        if (viewportSize > 800) {
            // ----DESKTOP VIEW---- //
            return (
                <>
                    <ModalPortal>
                        <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                        <ModalStyle>
                            <CloseButton>
                                <ModalCloseButton type={"button"} onClick={() => props.handleModalClose()}>X</ModalCloseButton>
                            </CloseButton>
                            {props?.type === "add" ?
                                <>
                                    <FormTitle className="d-flex justify-content-center mt-3">Add new gender</FormTitle>
                                    <hr />
                                    <LanguagesForm onSubmit={(e) => addPostGender(e)} >
                                        <FormControl>
                                            <NameFirst>
                                                <DivForLabelAndInputName className="forDisable">
                                                    <LabelProfile >Gender</LabelProfile>
                                                    <InputProfileForm required id="name_al" name="name_al" placeholder="Gjinia Shqip" type="text" onChange={(e) => setName_al(e.target.value)} />
                                                </DivForLabelAndInputName>
                                            </NameFirst>
                                            <NameFirst>
                                                <DivForLabelAndInputName className="forDisable">
                                                    <LabelProfile >Gender</LabelProfile>
                                                    <InputProfileForm required id="name_en" name="name_en" placeholder="Gender English" type="text" onChange={(e) => setName_en(e.target.value)} />
                                                </DivForLabelAndInputName>
                                            </NameFirst>
                                            <div className='d-flex justify-content-end mt-5 mb-5'>
                                                <ModalEditButtonAdd type="submit" >Add</ModalEditButtonAdd>
                                            </div>
                                        </FormControl>
                                    </LanguagesForm>
                                </>
                                :
                                props?.type === "edit" ?
                                    <>
                                        <FormTitle className="d-flex justify-content-center mt-3">Edit Gender </FormTitle>
                                        <hr />
                                        <LanguagesForm type={props?.type} onSubmit={handleEdit}>
                                            <FormControl>
                                                <NameFirst>
                                                    <DivForLabelAndInputName className="forDisable">
                                                        <LabelProfile >Gender</LabelProfile>
                                                        <InputProfileForm required id="name_al" name="name_al" placeholder="Gjinia Shqip" type="text" defaultValue={props?.dataToModal?.name_al} onChange={(e) => setName_al(e.target.value)} />
                                                    </DivForLabelAndInputName>
                                                </NameFirst>
                                                <NameFirst>
                                                    <DivForLabelAndInputName className="forDisable">
                                                        <LabelProfile >Gender</LabelProfile>
                                                        <InputProfileForm required id="name_en" name="name_en" placeholder="Gender English" type="text" defaultValue={props?.dataToModal?.name_en} onChange={(e) => setName_en(e.target.value)} />
                                                    </DivForLabelAndInputName>
                                                </NameFirst>
                                                <div className='d-flex justify-content-end mt-5 mb-5'>
                                                    <ModalEditButtonAdd type="submit" >Edit</ModalEditButtonAdd>
                                                </div>
                                            </FormControl>
                                        </LanguagesForm>
                                    </>
                                    : ""
                            }
                        </ModalStyle>
                    </ModalPortal>
                </>
            )
        }
        //   ----MOBILE VIEW----- //
        else {
            return (
                <>
                    <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                    <ModalStyleMobile>
                        <CloseButtonMobile>
                            <ModalCloseButtonMobile type={"button"} onClick={() => props.handleModalClose()}>X</ModalCloseButtonMobile>
                        </CloseButtonMobile>
                        {props?.type === "add" ?
                            <>
                                <FormTitle className="d-flex justify-content-center mt-3">Add new gender</FormTitle>
                                <hr />
                                <LanguagesForm onSubmit={(e) => addPostGender(e)} >
                                    <FormControl>
                                        <NameFirst>
                                            <DivForLabelAndInputName className="forDisable">
                                                <LabelProfile >Gender</LabelProfile>
                                                <InputProfileForm required id="name_al" name="name_al" placeholder="Gjinia Shqip" type="text" onChange={(e) => setName_al(e.target.value)} />
                                            </DivForLabelAndInputName>
                                        </NameFirst>
                                        <NameFirst>
                                            <DivForLabelAndInputName className="forDisable">
                                                <LabelProfile >Gender</LabelProfile>
                                                <InputProfileForm required id="name_en" name="name_en" placeholder="Gender English" type="text" onChange={(e) => setName_en(e.target.value)} />
                                            </DivForLabelAndInputName>
                                        </NameFirst>
                                        <div className='d-flex justify-content-end mt-5 mb-5'>
                                            <ModalEditButtonAdd type="submit" >Add</ModalEditButtonAdd>
                                        </div>
                                    </FormControl>

                                </LanguagesForm>
                            </>
                            :
                            props?.type === "edit" ?
                                <>
                                    <FormTitle className="d-flex justify-content-center mt-3">Edit gender</FormTitle>
                                    <hr />
                                    <LanguagesForm type={props?.type} onSubmit={handleEdit}>
                                        <FormControl>
                                            <NameFirst>
                                                <DivForLabelAndInputName className="forDisable">
                                                    <LabelProfile >Gender</LabelProfile>
                                                    <InputProfileForm required id="name_al" name="name_al" placeholder="Gjinia Shqip" type="text" defaultValue={props?.dataToModal?.name_al} onChange={(e) => setName_al(e.target.value)} />
                                                </DivForLabelAndInputName>
                                            </NameFirst>
                                            <NameFirst>
                                                <DivForLabelAndInputName className="forDisable">
                                                    <LabelProfile >Gender</LabelProfile>
                                                    <InputProfileForm required id="name_en" name="name_en" placeholder="Gender English" type="text" defaultValue={props?.dataToModal?.name_en} onChange={(e) => setName_en(e.target.value)} />
                                                </DivForLabelAndInputName>
                                            </NameFirst>
                                            <div className='d-flex justify-content-end mt-5 mb-5'>
                                                <ModalEditButtonAdd type="submit" >Edit</ModalEditButtonAdd>
                                            </div>
                                        </FormControl>
                                    </LanguagesForm>
                                </>
                                : ""
                        }
                    </ModalStyleMobile>
                </>
            )
        }
    }
    else
        return null
}
export default AddGender


