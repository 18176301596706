import React, { useState } from "react";
import ModalPortal from "../../../portal/ModalPortal";
import {
    UsersForm, FormControl, FormTitle, NameSurname, ModalAddButtonSalon,
    ModalDarkBackGround, ModalStyle, ModalCloseButton, SelectOptionBox,
    InputProfileForm, DivForLabelAndInputUser, ModalCloseButtonMobile,
    LabelProfile, SelectOption, DivForLabelAndInput, NameFirst, ModalStyleMobile, ModalEditButtonServicess, CloseButton
} from "../../styles/MainLayout"
import { useParams } from "react-router-dom";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { CloseButtonMobile } from "../../styles/MainLayoutResponsive";

const EditSalonModal = (props) => {
    const [Membership, setMembership] = useState([])
    const [users, setUser] = useState({});
    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });
    const { sid } = useParams();
    console.log("propsServices", props)

    ///AXIOS
    const getMembership = () => {
        axios
            .get("/api/admin/subscriptions/all")
            .then((response) => {
                console.log("olaLabels", response.data);
                setMembership(response.data)
            }).catch(err => {
                console.log(err)
            })
    }
    //axios

    //AXIOS
    const [owner, setOwner] = useState([])
    const UserData = () => {
        axios.get('/api/admin/users/activeStoreAdmins')
            .then(data => {
                console.log('all salon owners get req', data.data);
                setOwner(data.data)
            }).catch(err => console.log(err));
    }
    const [newOwner, setNewOwner] = useState([])
    const NewUserData = () => {
        axios.get('/api/admin/users/storeAdmins')
            .then(data => {
                console.log('all salon store admins', data.data);
                setNewOwner(data.data)
            }).catch(err => console.log(err));
    }


    //AXIOS

    useEffect(() => {
        getMembership()
        UserData()
        NewUserData()
    }, [])

    //AXIOS
    const [editSalonName, setEditSalonName] = useState()
    const SalonEdit = (e) => {
        e.preventDefault();
        let body = {
            id: props?.dataToModal?.id,
            owner_id: e?.target?.owner_id?.value,
            subscription_id: e?.target?.subscription_id?.value,
            name_al: e?.target?.name_al?.value,
            name_en: e?.target?.name_al?.value,
            desc_al: e?.target?.desc_al?.value,
            desc_en: e?.target?.desc_en?.value,
            created_at: new Date(),
        }
        console.log("body", body)
        axios.put(`/api/admin/salon/update`, body)
            .then((data) => {
                props?.handleModalClose();
                props?.getNewSalon()
                console.log(data.data)
                toast.success("Salon edited sucessfully")
            }).catch(
                err => {
                    toast.error(err)
                    console.log(err)
                }
            )
    }

    const date = new Date(props?.created_at)
    const formatDate = date?.toLocaleDateString()
    console.log("PROPSAT", props?.dataToModal)
    if (props?.isOpen) {
        if (viewportSize > 800) {
            //----DESKTOP VIEW---- //
            return (
                <>
                    <ModalPortal>
                        <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                        <ModalStyle>
                            <CloseButton>
                                <ModalCloseButton type={"button"} onClick={() => props.handleModalClose()}>X</ModalCloseButton>
                            </CloseButton>
                            <>
                                <FormTitle className="d-flex justify-content-center mt-3">Edit Salon</FormTitle>
                                <hr />
                                <UsersForm onSubmit={SalonEdit}>
                                    <FormControl>
                                        <NameSurname>
                                            <DivForLabelAndInputUser className="forDisable">
                                                <LabelProfile >Emri i Salonit</LabelProfile>
                                                <InputProfileForm defaultValue={props?.dataToModal?.name_al} onChange={(e) => setEditSalonName(e.target.value)} id="name_al" placeholder="Emri i sallonit" type="text" name="name_al" />
                                            </DivForLabelAndInputUser>
                                        </NameSurname>
                                        {/* <NameSurname> */}
                                        <SelectOptionBox className="row gap-3 m-0">

                                            <DivForLabelAndInputUser className="forDisable">
                                                <LabelProfile >Owner</LabelProfile>
                                                <SelectOption id="owner_id" name="owner_id" required>
                                                    <option hidden value="">--select owner--</option>
                                                    {owner?.filter(elm => elm.id == props?.dataToModal?.owner_id)?.map(el => {
                                                        return <option selected value={props?.dataToModal?.owner_id}>{el?.name} {el?.surname} - {el?.email} </option>

                                                    })}
                                                    {newOwner?.map(el => {
                                                        return <option value={el?.id} >{`${el?.name}  ${el?.surname} - ${el?.email} `}   </option>
                                                    })}
                                                </SelectOption>
                                            </DivForLabelAndInputUser>

                                            <DivForLabelAndInputUser className="forDisable">
                                                <LabelProfile >Membership</LabelProfile>
                                                <SelectOption id="subscription_id" name="subscription_id" required >
                                                    {Membership?.filter(elm => elm.id == props?.dataToModal?.subscription_id).map(el => {
                                                        return <option value={props?.dataToModal?.subscription_id}>{el?.name}</option>
                                                    })}
                                                    {Membership?.map(el => {
                                                        console.log('el diqka diqka', el)
                                                        return <option defaultValue={el?.name} value={el?.id}>{el.name} </option>
                                                    })}
                                                </SelectOption>
                                            </DivForLabelAndInputUser>
                                        </SelectOptionBox>
                                        {/* </NameSurname> */}
                                        <DivForLabelAndInputUser className="forDisable">
                                            <LabelProfile>Description Shqip</LabelProfile>
                                            <textarea defaultValue={props?.dataToModal?.desc_al} className="form-control" cols="30" rows="3" placeholder="Description..." required id="desc_al" name="desc_al"></textarea>
                                        </DivForLabelAndInputUser>
                                        <DivForLabelAndInputUser className="forDisable">
                                            <LabelProfile>Description English</LabelProfile>
                                            <textarea defaultValue={props?.dataToModal?.desc_en} className="form-control" cols="30" rows="3" placeholder="Description..." required id="desc_en" name="desc_en"></textarea>
                                        </DivForLabelAndInputUser>

                                        {/* <Surname>
                                                <DivForLabelAndInput className="forDisable">
                                                <LabelProfile> Service Name </LabelProfile>
                                                <InputProfileForm defaultValue={props.data.name_en} placeholder="Service name" type="text" name="name_en" />
                                                </DivForLabelAndInput>
                                            </Surname> */}
                                        {/* <br /> */}
                                        <p className="text-muted olatext" style={{ fontSize: '10px' }}><strong>Created at:</strong> '.{props?.dataToModal?.created_at.slice(0, 10)}'</p>
                                        <p className="text-muted olatext" style={{ fontSize: '10px' }}><strong>Updated at:</strong> '.{props?.dataToModal?.updated_at !== null ? props?.dataToModal?.updated_at : "Nuk eshte bere update deri me tani"}'</p>
                                        <ModalAddButtonSalon type={"submit"}>Edit</ModalAddButtonSalon>
                                    </FormControl>
                                </UsersForm>
                            </>
                        </ModalStyle>
                    </ModalPortal>
                </>
            )
        }
        //   ----MOBILE VIEW----- //
        else {
            return (
                <>

                    <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                    <ModalStyleMobile>
                        <CloseButtonMobile>

                            <ModalCloseButtonMobile type={"button"} onClick={() => props?.handleModalClose()}>X</ModalCloseButtonMobile>
                        </CloseButtonMobile>
                        <>
                            <FormTitle className="d-flex justify-content-center mt-3">Edit Salon</FormTitle>
                            <hr />
                            <UsersForm onSubmit={SalonEdit}>
                                <FormControl>
                                    <NameSurname>
                                        <NameFirst>
                                            <DivForLabelAndInput className="forDisable">
                                                <LabelProfile >Emri i Salonit</LabelProfile>
                                                <InputProfileForm defaultValue={props?.dataToModal?.name_al} onChange={(e) => setEditSalonName(e.target.value)} id="name_al" placeholder="Emri i sallonit" type="text" name="name_al" />
                                            </DivForLabelAndInput>
                                        </NameFirst>
                                        {/* <Surname>
                                            <Label> Service Name </Label>
                                            <Input placeholder="Service name" type="text" name="name_en" />
                                        </Surname> */}
                                    </NameSurname>


                                    <SelectOptionBox className="gap-3 d-flex mt-2">
                                        <DivForLabelAndInputUser className="forDisable">
                                            <LabelProfile >Owner</LabelProfile>
                                            <SelectOption id="owner_id" name="owner_id" required>
                                                <option hidden value="">--select owner--</option>
                                                {owner?.filter(elm => elm.id == props?.dataToModal?.owner_id)?.map(el => {
                                                    return <option selected value={props?.dataToModal?.owner_id}>{el.name} {el.surname} - {el?.email} </option>

                                                })}
                                                {newOwner?.map(el => {
                                                    return <option value={el?.id} >{`${el?.name}  ${el?.surname} - ${el?.email} `}   </option>
                                                })}
                                            </SelectOption>
                                        </DivForLabelAndInputUser>

                                        <DivForLabelAndInputUser className="forDisable">
                                            <LabelProfile >Membership</LabelProfile>
                                            <SelectOption id="subscription_id" name="subscription_id" required >
                                                {Membership?.filter(elm => elm.id == props?.dataToModal?.subscription_id)?.map(el => {
                                                    return <option value={props?.dataToModal?.subscription_id}>{el?.name}</option>
                                                })}
                                                {Membership?.map(el => {
                                                    console.log('el diqka diqka', el)
                                                    return <option defaultValue={el?.name} value={el?.id}>{el.name} </option>
                                                })}
                                            </SelectOption>
                                        </DivForLabelAndInputUser>
                                    </SelectOptionBox>
                                    {/* </NameSurname> */}
                                    <DivForLabelAndInputUser className="forDisable">
                                        <LabelProfile>Description </LabelProfile>
                                        <textarea defaultValue={props?.dataToModal?.desc_al} className="form-control" cols="30" rows="3" placeholder="Description Shqip" required id="desc_al" name="desc_al"></textarea>
                                    </DivForLabelAndInputUser>
                                    <DivForLabelAndInputUser className="forDisable">
                                        <LabelProfile>Description </LabelProfile>
                                        <textarea defaultValue={props?.dataToModal?.desc_en} className="form-control" cols="30" rows="3" placeholder="Description English" required id="desc_en" name="desc_en"></textarea>
                                    </DivForLabelAndInputUser>

                                    {/* <Surname>
                                                <DivForLabelAndInput className="forDisable">
                                                <LabelProfile> Service Name </LabelProfile>
                                                <InputProfileForm defaultValue={props.data.name_en} placeholder="Service name" type="text" name="name_en" />
                                                </DivForLabelAndInput>
                                            </Surname> */}
                                    <br />
                                    <p className="text-muted olatext" style={{ fontSize: '10px' }}><strong>Created at:</strong> '.{props?.dataToModal?.created_at.slice(0, 10)}'</p>
                                    <p className="text-muted olatext" style={{ fontSize: '10px' }}><strong>Updated at:</strong> '.{props?.dataToModal?.updated_at !== null ? props?.dataToModal?.updated_at : "Nuk eshte bere update deri me tani"}'</p>
                                    <br />
                                    <ModalEditButtonServicess type={"submit"}>Edit</ModalEditButtonServicess>
                                </FormControl>
                            </UsersForm>
                        </>
                    </ModalStyleMobile>
                </>
            )
        }
    }
    else
        return null
}

export default EditSalonModal
