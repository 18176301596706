import { useState } from 'react';
import React from 'react'
import { AddButton, PaginationStyle, TableStyle } from '../../styles/MainLayout';
import ReactPaginate from 'react-paginate';
import { setSideBar } from '../../../redux/Functions/actions';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from "../../../axios"
import { useEffect } from 'react';
import { CardStyle, AddButtonMobile } from "../../styles/MainLayoutResponsive";
import SubSubServiceModal from "../../Modal/SubsubServiceModal/SubSubServiceModal";
import SubSubServiceDeleteModal from "../../Modal/SubsubServiceModal/SubSubServiceDeleteModal";

import SearchInputHeader from '../SearchInputHeader';

const SalonSubServices = (props) => {
  const [subServiceData, setSubserviceData] = useState([])
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dataToModal, setDataToModal] = useState(null);
  const [modalType, setModalType] = useState(null);
  const sid = useParams()
  const [viewportSize, setViewportSize] = useState(window.innerWidth); //DESKTOP VIEW & MOBILE VIEW
  window.addEventListener("resize", () => {
    setViewportSize(window.innerWidth);
  });


  ////AXIOS
  const getSubService = () => {
    axios.get(`api/admin/sub_sub_services/single/${sid?.subid}`).then(data => {
        console.log('sidaaaa2', data)
        setSubserviceData(data?.data)
        setFilteredData(data.data)
      }).catch(err => console.log(err));
  }
  useEffect(() => {
    getSubService()
  }, [])

    // FILTER FUNCTION
  const [filteredData, setFilteredData] = useState();
  const filterByContent = async (filterValue) => {
    if (filterValue) {
      const filteredArray = subServiceData.filter((el) =>
        el?.name_al?.toLowerCase().includes(filterValue?.toLowerCase())
      );
      setFilteredData(filteredArray);
    } else if (filterValue === "") {
      setFilteredData(subServiceData);
    }
  };

    //PAGINATION
    const [currentPage, setCurrentPage] = useState(false);
    const startIndex = currentPage * 8;
    const endIndex = startIndex + 8;
    const numPages = Math.ceil(filteredData?.length / 8)
  
    const data = filteredData?.slice(startIndex, endIndex);
    const handlePageChange = ({ selected }) => {
      setCurrentPage(selected);
    };
  //PAGINATION


  const handleModalAdd = () => {
    setIsOpen(true);
    setModalType("add");
  };

  const handleModalEdit = (data) => {
    setIsOpen(true)
    setModalType('edit')
    setDataToModal(data)

  }

  const handleModalDelete = (data) => {
    setIsOpenDelete(true);
    setModalType("delete");
    setDataToModal(data)
  }
  const handleModalClose = () => {
    setIsOpen(false);
    setIsOpenDelete(false)
  };

  if (viewportSize > 800)
    return (
      <>
        <SearchInputHeader filterByContent={filterByContent}/>
        {isOpen === true ? (
          <SubSubServiceModal
            getSubService={getSubService}
            data={dataToModal}
            type={modalType}
            isOpen={isOpen}
            handleModalClose={handleModalClose}
          />
        ) : (
          ""
        )}
        {isOpenDelete === true ? (
          <SubSubServiceDeleteModal
            // getNewUsers={() => UserData()}
            isOpen={isOpenDelete}
            type={modalType}
            dataToModal={dataToModal}
            handleModalClose={handleModalClose}
            getSubService={getSubService}
          />
        ) : (

          ""
        )}
        <TableStyle collapse={props?.sidebar}>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col-1">ID</th>
                <th scope="col-3">Serviset Al</th>
                <th scope="col-5"> Salon EN </th>
                <th scope="col-3">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((el) => {
                //CREATED AT FORMAT TO DATE MONTH YEAR ONLY
                const date = new Date(el?.created_at)
                const formatDate = date?.toLocaleDateString()
                return (
                  <>
                    <tr>
                      <th scope="row" className="col-1"> {el?.id}</th>
                      <td className="col-5">{el?.name_al}</td>
                      <td className="col-5">
                        {el?.name_en}
                        </td>
                      <td className="col">
                        <div className="d-flex justify-content-center gap-3">
                          <i class="fa-regular fa-pen-to-square iconsForCursor"
                            onClick={() => handleModalEdit(el)}
                          />
                          <i class="fa-regular fa-trash-can ms-1 iconsForCursor"
                            onClick={() => handleModalDelete(el)}
                          />
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </TableStyle>
        <PaginationStyle collapse={props?.sidebar}>
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={numPages}
            onPageChange={handlePageChange}
            containerClassName={"pagination justify-content-center"}
            activeClassName={"active"}
            currentPage={data?.length > 7 ? true : false}
          />
        </PaginationStyle>
        <AddButton>
          <i
            type={"button"}
            className="fa-solid fa-circle-plus fa-xl"
            style={{ color: "#FF5758" }}
            onClick={() => handleModalAdd()}
          ></i>
        </AddButton>
      </>
    )

  else {
    return (
      <>
        <SearchInputHeader filterByContent={filterByContent} />
        {isOpen === true ? (
          <SubSubServiceModal
            getSubService={getSubService}
            data={dataToModal}
            type={modalType}
            isOpen={isOpen}
            handleModalClose={handleModalClose}
          />
        ) : (
          ""
        )}
        {isOpenDelete === true ? (
          <SubSubServiceDeleteModal
            // getNewUsers={() => UserData()}
            isOpen={isOpenDelete}
            type={modalType}
            dataToModal={dataToModal}
            handleModalClose={handleModalClose}
          />
        ) : (

          ""
        )}
        <div className="container">
          {data?.map((el) => {
            //CREATED AT FORMAT TO DATE MONTH YEAR ONLY
            const date = new Date(el?.created_at)
            const formatDate = date?.toLocaleDateString()
            return (
              <>
                <CardStyle>
                  <div className="mb-3">
                    <div className="row">
                      <div className="col-8">
                        <ul class="list-group gap-3 pt-2 pb-2 ps-3">
                          <li class="list-group"><strong>Service Al:</strong> {el?.name_al}</li>
                          <li class="list-group"> <strong> Service EN:   </strong>    {el?.name_en}</li>
                        </ul>
                      </div>
                      <div className="col-3 pt-2">
                        <div className="d-flex justify-content-center gap-3">
                        <i class="fa-regular fa-pen-to-square"
                            onClick={() => handleModalEdit(el)}
                          />
                           <i class="fa-regular fa-trash-can ms-1"
                            onClick={() => handleModalDelete(el)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </CardStyle>
              </>
            );
          })}
          <AddButtonMobile>
            <i
              class="fa-solid fa-circle-plus d-flex flex-row-reverse fa-2xl addButtonLanguageMobile"
              onClick={() => handleModalAdd()}
            />
          </AddButtonMobile>
        </div>
      </>
    );
  }

}
const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    sidebar: state?.data?.sidebar,
    dataFilter: state?.data?.dataFilter
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSideBar: (data) => dispatch(setSideBar(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SalonSubServices);
