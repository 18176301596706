import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Users from './pages/users/Users';
import Membership from './pages/membership/memberships';
import Services from './pages/services/services';
import Sidebar from './components/sidebar/sidebar';
import Salons from './pages/salons/Salons';
import Login from './pages/Login/Login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Profile from './pages/profile/profile';
import Language from './pages/Language/Language';
import AddSalonView from './pages/salons/AddSalon/AddSalon';
import 'react-toastify/dist/ReactToastify.css';
import EditSalonView from './pages/salons/EditSalon/editSalon';
import { connect } from "react-redux";
// import Register from './pages/Register/Register';
import NotFound from './components/404NotFound/404';
import SalonAddresses from './pages/salons/salonAddresses/salonAddresses';
import AllServices from './components/form/Services/AllServices';
import AddLocation from './components/Modal/salonModals/addLocation';
import SalonSubServices from './components/form/SalonSubServices/SalonSubServices';
import { setLoggedIn, setExpiredToken, setUserToken, setUserData } from './redux/Functions/actions';
import { useEffect } from 'react';
import 'react-image-lightbox/style.css';
import Gender from './components/form/Genders/Gender';



function App( props) {
  // THE VALIDATION OF THE ROUTE NEEDS TO BE DONE UNDER ONE CONDITION, HERE YOU NEED TO CHANGE ONE OF THE ROUTES PATH (CALENDAR !) TO PATH="/" IN ORDER TO NOT NAVIGATE FROM ONE PAGE TO ANOTHER BUT TO ONLY RENDER DIFERRENT ROUTES!
  useEffect(() => {
    const currentDate = new Date();
    const expireDate = new Date(props?.expire_token);
    // console.log("expireToken", props?.expire_token)
    if (props?.logged_in && expireDate && expireDate.getDate() !== currentDate.getDate()) {
      alert("You need to login again to have access to this site!");
      props.setExpiredToken(null);
      props.setLoggedIn(false);
      console.log('appprops', props)
      props.setUserToken(null);
      props.setUserData([]);
    }
  }, []);
  return (
    <>
      <ToastContainer position="bottom-right" theme="colored" style={{ zIndex: '9999' }} />
      <Router>
        {props?.logged_in === true && <Sidebar />}
        <Routes>
          {props?.logged_in === false ? (
            <>
              <Route exact path="/" element={<Login />} />
              {/* <Route exact path="/register" element={<Register />} /> */}
            </>)
            :
            <Route path="*" element={<Login />} />
          }
          {
            props?.logged_in === true ?
              (
                <>
                  {/* <Route exact path="/" element={<Appointments />} /> */}
                  <Route path="/" element={<Users />} />
                  <Route  path="/addsalon" element={<AddSalonView />} />
                  <Route path="/editsalon/:id" element={< EditSalonView />} />
                  <Route path="/profile/:pid" element={<Profile />} />
                  <Route path="/membership" element={<Membership />} />
                  <Route path="/services/:sid" element={<Services />} />
                  <Route path="/AllServices" element={<AllServices />} />
                  <Route path="/salon_service/:sid/:subid" element={<SalonSubServices />} />
                  <Route path="/salons" element={<Salons />} />
                  <Route path="/language" element={<Language />} />
                  <Route path="/gender" element={<Gender />} />
                  <Route path="/add_location" element={<AddLocation />} />
                  <Route path="/salonAddresses/:id" element={<SalonAddresses />} />
                </>)
              :
              <>
                <Route path={"/addsalon" && "/profile" && "/membership" && "/services"&&"/salons" && "/language" && "/salon_service/:sid/:id" && "/editsalon/:id" } element={<Login />} />
                <Route path="*" element={<NotFound />} />
              </>


          }
        </Routes>
      </Router>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    logged_in: state.data.logged_in,
    user_data: state?.data?.user_data,
    expire_token: state?.data?.expire_token

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedIn: (data) => dispatch(setLoggedIn(data)),
    setUserData: (data) => dispatch(setUserData(data)),
    setUserToken: (data) => dispatch(setUserToken(data)),
    setExpiredToken: (data) => dispatch(setExpiredToken(data)),

  };
};



export default connect(mapStateToProps, mapDispatchToProps)(App);
// export default SearchInputHeader;
// export default App;
