import React from "react";
import { useState } from "react";
import { TableStyle, AddButton, PaginationStyle, TableServiceName, ServicesTableName } from "../styles/MainLayout"
import { CardStyle, AddButtonMobile } from "../styles/MainLayoutResponsive"
import ServiceModal from "../Modal/ServiceModal"
import { setSideBar } from "../../redux/Functions/actions";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import SearchInputHeader from "./SearchInputHeader";
import "../../components/form/Services/services.scss"
import axios from "../../axios";
import { useEffect } from "react";
import SubServiceModal from "../Modal/subServiceModal/SubServiceModal";
import SubServiceDeleteModal from '../../components/Modal/subServiceModal/SubServiceDeleteModal'
import { Link, useParams } from "react-router-dom";
import "../../components/form/Services/services.scss"


const Services = (props) => {
    const { sid } = useParams();
    console.log("SID", sid)

    const [isOpen, setIsOpen] = useState(false)
    const [modalType, setModalType] = useState()
    const [serviceData, setServiceData] = useState()
    const [subServices, setSubServices] = useState([])
    const [dataToModal, setDataToModal] = useState(null);
    const [isOpenDelete, setIsOpenDelete] = useState(false);



    // PAGINATION
    const [currentPage, setCurrentPage] = useState(0);
    const startIndex = currentPage * 8;
    const endIndex = startIndex + 8;
    const numPages = Math.ceil(subServices.length / 8)

    const data = subServices.slice(startIndex, endIndex);

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };
    //PAGINATION


    const [services, setServices] = useState([])
    const name = services.filter(el => el.id == sid).map(el => el.name_al)
    const getAllServices = () => {
        axios.get('/api/admin/services/all').then(
            data => {
                console.log("  21213123123 ", data.data)
                setServices(data.data)
            }
        ).catch(
            err => {
                console.log(err)
            }
        )
    }





    //AXIOS

    const getServices = () => {
        axios.get(`/api/admin/sub_services/single/${sid}`).then(
            data => {
                console.log("serviset", data.data)
                setSubServices(data.data)
            }
        ).catch(
            err => {
                console.log(err)
            }
        )
    }
    useEffect(() => {
        getServices();
        getAllServices();
    }, [sid])
    //Functions
    const handleModalAdd = () => {
        setModalType("add")
        setIsOpen(true)
    }
    const handleModalEdit = (data) => {
        setModalType("edit")
        setIsOpen(true)
        setServiceData(data)
    }
    const handleModalView = (data) => {
        setModalType("view")
        setIsOpen(true)
        setServiceData(data)
    }
    const handleModalClose = () => {
        setIsOpen(false)
        setIsOpenDelete(false)
    }

    const handleModalDelete = (data) => {
        setIsOpenDelete(true);
        setModalType("delete");
        setDataToModal(data)
    }

    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    window.addEventListener('resize', () => {
        setViewportSize(window.innerWidth);
    });
    if (viewportSize > 800) {
        return (
            <>
                <SearchInputHeader />

                {isOpen === true ?
                    <SubServiceModal isOpen={isOpen} type={modalType} handleModalClose={handleModalClose} data={serviceData} getData={() => getServices()} />
                    :
                    ""
                }
                {isOpenDelete === true ? (
                    <SubServiceDeleteModal
                        getNewSubService={() => getServices()}
                        isOpen={isOpenDelete}
                        type={modalType}
                        dataToModal={dataToModal}
                        handleModalClose={handleModalClose}
                    />
                ) : (

                    ""
                )}

                <ServicesTableName collapse={props?.sidebar}>
                    <TableServiceName>Service: {name} </TableServiceName>
                </ServicesTableName>
                <TableStyle collapse={props?.sidebar}>
                    <table className="table table-hover">
                        <tr>
                            <th className="col-1">ID</th>
                            <th className="col-5">Serviset Al</th>
                            <th className="col-5"> Service En</th>
                            <th className="col-3">Actions</th>
                        </tr>
                        {data.map(el => {
                            return (
                                <>
                                    <tr>
                                        <th className="col-1">{el?.id}</th>
                                        <td className="col-4">{el?.name_al}</td>
                                        <td className="col-3">
                                            {el?.name_en}
                                        </td>
                                        <td className="col-3">
                                            <div className="d-flex gap-3">
                                                <Link class="fa-regular fa-eye eyeStyle iconsForCursor" to={`/salon_service/${sid}/${el?.id}`} />
                                                <i class="fa-regular fa-pen-to-square iconsForCursor" onClick={() => handleModalEdit(el)} />
                                                <i class="fa-regular fa-trash-can iconsForCursor" onClick={() => handleModalDelete(el)} />

                                            </div>
                                        </td>
                                    </tr>
                                </>
                            )
                        })}
                    </table >
                </TableStyle >


                <PaginationStyle collapse={props?.sidebar}>
                    <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={numPages}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination justify-content-center"}
                        activeClassName={"active"}
                    />
                </PaginationStyle>

                <AddButton onClick={() => handleModalAdd()}>
                    <i type={"button"} class="fa-solid fa-circle-plus fa-xl" style={{ color: "#FF5758" }}></i>
                </AddButton>
            </>
        )
    }
    else {
        return (
            <>
                <SearchInputHeader />
                {isOpen === true ?
                    <SubServiceModal isOpen={isOpen} type={modalType} handleModalClose={handleModalClose} data={serviceData} />
                    :
                    ""
                }
                {isOpenDelete === true ? (
                    <SubServiceDeleteModal
                        getNewSubService={() => getServices()}
                        isOpen={isOpenDelete}
                        type={modalType}
                        dataToModal={dataToModal}
                        handleModalClose={handleModalClose}
                    />
                ) : (

                    ""
                )}
                <div className="container serviceModal" />
                {data.filter(el => el.service_id == sid).map(el => {
                    return (
                        <>
                            <CardStyle>
                                <div className="mb-3">
                                    <div className="row">
                                        <div className="col-8">
                                            <ul class="list-group gap-2 pt-2 pb-2 ps-3">
                                                <li class="list-group"><strong>Service Al:</strong> {el.name_al} </li>
                                                <li class="list-group">
                                                  <strong>  Service En :</strong>{el?.name_en} 
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-3 pt-2">
                                            <div className="d-flex gap-2 pt-2">
                                                <Link class="fa-regular fa-eye eyeStyle" to={`/salon_service/${sid}/${el?.id}`} />
                                                <i class="fa-regular fa-pen-to-square iconsForCursor " onClick={() => handleModalEdit(el)}></i>
                                                <i class="fa-regular fa-trash-can iconsForCursor" onClick={() => handleModalDelete(el)} />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardStyle>
                            <AddButtonMobile onClick={() => handleModalAdd()}>
                                <i type={"button"} class="fa-solid fa-circle-plus fa-2xl iconsForCursor" style={{ color: "#FF5758" }}></i>
                            </AddButtonMobile>
                        </>
                    )
                })}

            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        selected_lang: state.data.selected_lang,
        logged_in: state.data.logged_in,
        sidebar: state?.data?.sidebar
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSideBar: (data) => dispatch(setSideBar(data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Services);
// export default Services;